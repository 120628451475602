import { useForm } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom';
import { useMemo, useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';

import Box from '@mui/material/Box';

import { useBoolean } from 'src/hooks/use-boolean';

import { useGetZapiIntegrationQuery, useGetStoreIntegrationsQuery } from 'src/graphql/generated';

import FormProvider from 'src/components/hook-form/form-provider';

import { IntegrationCard } from './utils/card-intergration';
import { TCardIntegration } from './types/card-integration';
import { CardIntegration } from './components/card-integration';
import { ConfigTrayModal } from './components/config-tray-modal';
import { ConfigZapiModal } from './components/config-zApi-modal';
import { ConfigOmieModal } from './components/config-omie-modal';
import { ConfigBlingModal } from './components/config-bling-modal';
import { ConfigDapicModal } from './components/config-dapic-modal';
import { ConfigWhatsappModal } from './components/config-whatsapp-modal';
import { ConfigSMSInfobipModal } from './components/config-smsinfobip-modal';
import { EmailProviderModal } from './components/config-email-provider-modal';
import ConfigIntegrationsToolbar from './components/config-integrations-toolbar';
import { IntegrationFormSchema, integrationFormSchemaDefaultValues } from './schema/integration-filter-form-schema';

// ----------------------------------------------------------------------

enum Integrations {
  WHATSAPP = 'WHATSAPP',
  TRAY = 'TRAY',
  ZAPI = 'ZAPI',
  OMIE = 'OMIE',
  BLING = 'BLING',
  SMS = 'SMS',
  DAPIC = 'DAPIC',
  EMAIL = 'EMAIL'
}

// ----------------------------------------------------------------------

export default function ConfigIntegrationsTab() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filteredCards, setFilteredCards] = useState<TCardIntegration[] | null>(null);

  const methods = useForm({
    resolver: yupResolver(IntegrationFormSchema),
    defaultValues: integrationFormSchemaDefaultValues,
  });

  const { watch } = methods;

  const watched = watch();

  const whatsappModal = useBoolean();
  const trayModal = useBoolean();
  const zapiModal = useBoolean();
  const omieModal = useBoolean();
  const blingModal = useBoolean();
  const smsInfobipModal = useBoolean();
  const dapicModal = useBoolean();
  const emailProviderModal = useBoolean();

  const { data: integrationData, refetch } = useGetStoreIntegrationsQuery();
  const { data: zapiData, refetch: refetchZapi } = useGetZapiIntegrationQuery();

  const integratedTray = Boolean(integrationData?.infoIntegration?.traystoreintegration?.connected);
  const integratedOmie = Boolean(integrationData?.infoIntegration?.omiestoreintegration?.activated);
  const integratedZapi = Boolean(zapiData?.getZApiIntegration?.connected);
  const integratedSMSInfobip = Boolean(
    integrationData?.infoIntegration?.smsinfobipintegration?.activated
  );
  const integratedBling = Boolean(
    integrationData?.infoIntegration?.blingstoreintegration?.connected
  );
  const integratedDapic = Boolean(
    integrationData?.infoIntegration?.webpicstoreintegration?.connected
  );
  const integratedEmailProvider = Boolean(
    integrationData?.infoIntegration?.emailproviderstoreintegration?.isActive
  );

  const currentDataTray = integrationData?.infoIntegration?.traystoreintegration;
  const currentDataWhatsapp = integrationData?.infoIntegration?.whatsappconfig;
  const currentDataOmie = integrationData?.infoIntegration?.omiestoreintegration;
  const currentDataZapi = zapiData?.getZApiIntegration;
  const currentDataSMSInfobip = integrationData?.infoIntegration?.smsinfobipintegration;
  const currentDataBling = integrationData?.infoIntegration?.blingstoreintegration;
  const currentDataDapic = integrationData?.infoIntegration?.webpicstoreintegration;
  const currentDataEmailProvider = integrationData?.infoIntegration?.emailproviderstoreintegration;

  const essentialWppFields = {
    whatsappAccessToken: currentDataWhatsapp?.whatsappAccessToken || '',
    phoneNumberId: currentDataWhatsapp?.phoneNumberId || '',
    whatsappAppBusinessId: currentDataWhatsapp?.whatsappAppBusinessId || '',
    whatsappBusinessAccountId: currentDataWhatsapp?.whatsappBusinessAccountId || '',
  };

  const verifyIfWppNotEmpty = Object.values(essentialWppFields || {}).every(
    (value) => value !== ''
  );

  const allDataCardIntegrations: TCardIntegration[] = [
    {
      logoURL: "/assets/images/tray-logo.png",
      heading: "Tray",
      subheading: "Integração com a conta da tray",
      isConnected: integratedTray,
      hasData: Boolean(currentDataTray),
      onAction: trayModal.onTrue,
      type: IntegrationCard.SYSTEM,
    },
    {
      logoURL: "/assets/images/omie-logo.png",
      heading: "Omie",
      subheading: "Integração com a conta da Omie",
      isConnected: integratedOmie,
      hasData: Boolean(currentDataOmie),
      onAction: omieModal.onTrue,
      type: IntegrationCard.SYSTEM,
    },
    {
      logoURL: "/assets/images/bling-logo.png",
      heading: "Bling",
      subheading: "Integração com o a conta da Bling",
      isConnected: integratedBling,
      hasData: Boolean(currentDataBling),
      onAction: blingModal.onTrue,
      type: IntegrationCard.SYSTEM,
    },
    {
      logoURL: "/assets/images/whatsapp-logo.png",
      heading: "WhatsApp Business",
      subheading: "Integração com a conta do WhatsApp",
      isConnected: verifyIfWppNotEmpty,
      hasData: Boolean(verifyIfWppNotEmpty),
      onAction: whatsappModal.onTrue,
      type: IntegrationCard.COMMUNICATION,
    },
    {
      logoURL: "/assets/zapi-dark.png",
      heading: "Z-Api",
      subheading: "Integração com a conta do Z-Api",
      isConnected: integratedZapi,
      hasData: Boolean(currentDataZapi),
      onAction: zapiModal.onTrue,
      type: IntegrationCard.COMMUNICATION,
    },
    {
      logoURL: "/assets/images/sms-logo.png",
      heading: "Infobip",
      subheading: "Integração com o serviço da Infobip",
      isConnected: integratedSMSInfobip,
      hasData: Boolean(currentDataSMSInfobip),
      onAction: smsInfobipModal.onTrue,
      type: IntegrationCard.COMMUNICATION,
    },
    {
      logoURL: "/assets/images/dapic-logo.png",
      heading: "Dapic",
      subheading: "Integração com a conta Dapic - WebPic",
      isConnected: integratedDapic,
      hasData: Boolean(currentDataDapic),
      onAction: dapicModal.onTrue,
      type: IntegrationCard.SYSTEM,
    },
    {
      logoURL: "/assets/images/email-logo.png",
      heading: "E-mail",
      subheading: "Integração com o serviço de E-mail",
      isConnected: integratedEmailProvider,
      hasData: Boolean(currentDataEmailProvider),
      onAction: emailProviderModal.onTrue,
      type: IntegrationCard.COMMUNICATION,
    }
  ];

  const handleCloseModal = (modalName: Integrations) => {
    switch (modalName) {
      case Integrations.TRAY:
        trayModal.onFalse();
        break;
      case Integrations.OMIE:
        omieModal.onFalse();
        break;
      case Integrations.WHATSAPP:
        whatsappModal.onFalse();
        break;
      case Integrations.ZAPI:
        zapiModal.onFalse();
        break;
      case Integrations.SMS:
        smsInfobipModal.onFalse();
        break;
      case Integrations.BLING:
        blingModal.onFalse();
        break;
      case Integrations.DAPIC:
        dapicModal.onFalse();
        break;
      case Integrations.EMAIL:
        emailProviderModal.onFalse();
        break;
      default:
        break;
    }

    const newSearchParams = new URLSearchParams(searchParams);

    newSearchParams.delete('modal');

    setSearchParams(newSearchParams);
  };

  const cardIntegration = useMemo(() => watched?.cardIntegration, [watched?.cardIntegration]);

  useEffect(() => {
    const modal = searchParams.get('modal');

    switch (modal) {
      case 'tray':
        if (!trayModal.value) trayModal.onTrue();
        break;
      case 'omie':
        if (!omieModal.value) omieModal.onTrue();
        break;
      case 'whatsapp':
        if (!whatsappModal.value) whatsappModal.onTrue();
        break;
      case 'zapi':
        if (!zapiModal.value) zapiModal.onTrue();
        break;
      case 'smsinfobip':
        if (!smsInfobipModal.value) smsInfobipModal.onTrue();
        break;

      default:
        break;
    }
  }, [searchParams, trayModal, omieModal, whatsappModal, zapiModal, smsInfobipModal]);

  useEffect(() => {
    const code = searchParams.get('code');

    if (code) {
      if (!blingModal.value) blingModal.onTrue();
    }

    // eslint-disable-next-line
  }, [searchParams]);

  useEffect(() => {
    if(!cardIntegration || cardIntegration.length === 0) {
      setFilteredCards(allDataCardIntegrations);

      return;
    }

    const currentCards: TCardIntegration[] = [];

    cardIntegration?.forEach((cardTypeSelected: any) => {
      const matchingCards = allDataCardIntegrations.filter(
        card => card.type === cardTypeSelected.value
      );

      currentCards.push(...matchingCards);
    });


    setFilteredCards(currentCards);

    // eslint-disable-next-line
  }, [cardIntegration, integrationData]);

  return (
    <FormProvider methods={methods}>
      <ConfigIntegrationsToolbar />
      <Box
        gap={3}
        display="grid"
        mt={3}
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
          md: 'repeat(3, 1fr)',
        }}
      >
        {
          filteredCards?.map((card, idx) => <CardIntegration key={`${card?.heading}-${(idx + 1) * Math.random()}`} {...card} />)
        }
      </Box>

      {/* // ---------------- Modais --------------- */}
      <ConfigTrayModal
        open={trayModal.value}
        onClose={() => handleCloseModal(Integrations.TRAY)}
        currentData={currentDataTray}
        disableForm={Boolean(currentDataTray)}
        refetch={refetch}
      />
      <ConfigOmieModal
        open={omieModal.value}
        onClose={() => handleCloseModal(Integrations.OMIE)}
        currentData={currentDataOmie}
        disableForm={Boolean(currentDataOmie)}
        refetch={refetch}
      />
      <ConfigWhatsappModal
        open={whatsappModal.value}
        onClose={() => handleCloseModal(Integrations.WHATSAPP)}
        // @ts-ignore
        currentData={currentDataWhatsapp}
        disableForm={Boolean(currentDataWhatsapp)}
        refetch={refetch}
        hasData={verifyIfWppNotEmpty}
      />
      <ConfigZapiModal
        open={zapiModal.value}
        onClose={() => handleCloseModal(Integrations.ZAPI)}
        currentData={currentDataZapi}
        refetch={refetchZapi}
      />
      <ConfigSMSInfobipModal
        open={smsInfobipModal.value}
        onClose={() => handleCloseModal(Integrations.SMS)}
        currentData={currentDataSMSInfobip}
        refetch={refetch}
      />
      <ConfigBlingModal
        open={blingModal.value}
        onClose={() => handleCloseModal(Integrations.BLING)}
        currentData={currentDataSMSInfobip}
        code={searchParams.get('code') as string | null}
        refetch={refetch}
      />

      <ConfigDapicModal
        open={dapicModal.value}
        onClose={() => handleCloseModal(Integrations.DAPIC)}
        currentData={currentDataDapic}
        refetch={refetch}
      />
      <EmailProviderModal
        open={emailProviderModal.value}
        onClose={() => handleCloseModal(Integrations.EMAIL)}
        currentData={currentDataEmailProvider}
        refetch={refetch}
      />
    </FormProvider>
  );
}
