import { m } from 'framer-motion';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import { useHasPermission } from 'src/hooks/use-has-permission';

import { PermissionUserType } from 'src/graphql/generated';
import { ForbiddenIllustration } from 'src/assets/illustrations';

import { varBounce, MotionContainer } from 'src/components/animate';

// ----------------------------------------------------------------------

type PermissionGuardProp = {
  permissions: PermissionUserType[];
  children: React.ReactNode;
};

export default function PermissionGuard({ children, permissions }: PermissionGuardProp) {
  const { hasAdminPermission, hasSellerPermissions, hasEmployeePermission, isLoading } = useHasPermission()
  const isAdminAndHasPermission = permissions.includes(PermissionUserType.Admin)  && hasAdminPermission
  const isEmployeeAndHasPermission = (
    permissions.includes(PermissionUserType.Employee)
    && (hasEmployeePermission || hasAdminPermission)
  )
  const isSellerAndHasPermission = permissions.includes(PermissionUserType.Seller)  && hasSellerPermissions

  if (isLoading) return <>{children}</>;
  if (isAdminAndHasPermission || isEmployeeAndHasPermission || isSellerAndHasPermission) return <>{children}</>

  return <Container component={MotionContainer} sx={{ textAlign: 'center' }}>
    <m.div variants={varBounce().in}>
      <Typography variant="h3" sx={{ mb: 2 }}>
        Permissão negada
      </Typography>
    </m.div>

    <m.div variants={varBounce().in}>
      <Typography sx={{ color: 'text.secondary' }}>
        Você não tem permissão para acessar esta página
      </Typography>
    </m.div>

    <m.div variants={varBounce().in}>
      <ForbiddenIllustration
        sx={{
          height: 260,
          my: { xs: 5, sm: 10 },
        }}
      />
    </m.div>
  </Container>
}
