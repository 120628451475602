import { useSnackbar } from 'notistack';

import { Box } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import MenuItem from '@mui/material/MenuItem';
import { Avatar, MenuList } from '@mui/material';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';

import { useResponsive } from 'src/hooks/use-responsive';

import { userTypeChoices } from 'src/utils/translate-enum-to-porteguese';

import {
  GetProfileQuery,
  PermissionUserType,
  useUpdateMyConnectedStoreMutation,
} from 'src/graphql/generated';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

// ----------------------------------------------------------------------

export default function ConnectedStorePopover({ profile }: { profile: GetProfileQuery }) {
  const popover = usePopover();

  const [executeUpdateConnectedStore, updateConnectedStoreData] =
    useUpdateMyConnectedStoreMutation();
  const { enqueueSnackbar } = useSnackbar();
  const isUniqueStore = !!profile?.me?.permissions?.length && profile?.me?.permissions?.length <= 1;

  const mediaQuery = 'sm';
  const mdDown = useResponsive('down', 'md');
  const imagePlaceholder =
    'https://image.crisp.chat/avatar/website/67d5e673-0291-45e6-8eb5-2b0e1aa79e06/120/?1720676554415';

  const planChoices = {
    FREE: 'Free',
    PRO: 'Pro',
  };

  async function onUpdateConnectedStore(storeId: string) {
    try {
      popover.onClose();
      const { data } = await executeUpdateConnectedStore({ variables: { storeId } });
      if (data?.updateUserConnectedStore?.ok) {
        enqueueSnackbar('Loja alterada com sucesso!');
        window.location.reload();
        return;
      }
      enqueueSnackbar('Erro ao alterar loja!', { variant: 'error' });
    } catch (error) {
      if (error?.message) {
        enqueueSnackbar(error?.message, { variant: 'error' });
        return;
      }
      enqueueSnackbar('Erro ao alterar loja!', { variant: 'error' });
    }
  }

  if (isUniqueStore) {
    return (
      <>
        {profile?.me?.connectedStore?.profileImage ? (
          <Box
            component="img"
            alt="Logo da Loja"
            src={profile.me.connectedStore.profileImage}
            sx={{ width: 24, height: 24, borderRadius: '50%' }}
          />
        ) : (
          <Box
            component="img"
            alt="Logo da Loja"
            src="/avatar/zeki.png"
            sx={{ width: 24, height: 24, borderRadius: '50%' }}
          />
        )}

        <Typography variant="subtitle2">
          {profile?.me?.connectedStore?.name?.length! >= 10 && mdDown
            ? `${profile?.me?.connectedStore?.name.substring(0, 10)}...`
            : profile?.me?.connectedStore?.name}
        </Typography>

        {profile?.me?.connectedStore?.isActive && (
          <Label
            color={profile?.me?.connectedStore?.plan === 'FREE' ? 'default' : 'info'}
            sx={{
              height: 22,
              display: { xs: 'none', [mediaQuery]: 'inline-flex' },
            }}
          >
            {planChoices[profile?.me?.connectedStore?.plan as keyof typeof planChoices]}
          </Label>
        )}

        {!profile?.me?.connectedStore?.isActive && (
          <Label color="default" variant="outlined">
            Inativo
          </Label>
        )}
      </>
    );
  }

  return (
    <>
      <LoadingButton
        sx={{
          py: 0.5,
          gap: { xs: 0.5, md: 1 },
          backgroundColor: popover.open ? 'grey.300' : 'inherit',
          border: popover.open ? '2px solid grey' : '2px solid transparent',
          '&:hover': {
            backgroundColor: popover.open ? 'grey.300' : 'grey.100',
          },
          cursor: 'pointer',
        }}
        onClick={popover.onOpen}
        loading={updateConnectedStoreData.loading}
        disabled={updateConnectedStoreData.loading}
        aria-disabled="true"
      >
        {profile?.me?.connectedStore?.profileImage && (
          <Box
            component="img"
            alt="Logo da Loja"
            src={profile.me.connectedStore.profileImage}
            sx={{ width: 24, height: 24, borderRadius: '50%' }}
          />
        )}
        <Typography variant="subtitle2">
          {profile?.me?.connectedStore?.name?.length! >= 10 && mdDown
            ? `${profile?.me?.connectedStore?.name.substring(0, 10)}...`
            : profile?.me?.connectedStore?.name}
        </Typography>

        {profile?.me?.connectedStore?.isActive && (
          <Label
            color={profile?.me?.connectedStore?.plan === 'FREE' ? 'default' : 'info'}
            sx={{
              height: 22,
              display: { xs: 'none', [mediaQuery]: 'inline-flex' },
            }}
          >
            {planChoices[profile?.me?.connectedStore?.plan as keyof typeof planChoices]}
          </Label>
        )}

        {!profile?.me?.connectedStore?.isActive && (
          <Label color="default" variant="outlined">
            Inativo
          </Label>
        )}

        <Iconify
          width={16}
          icon="carbon:chevron-sort"
          color={popover.open ? 'inherit' : 'default'}
        />
      </LoadingButton>

      <CustomPopover open={popover.open} onClose={popover.onClose} arrow="top-left" sx={{ mt: 1 }}>
        <MenuList sx={{ minWidth: 240 }}>
          {profile.me?.permissions.map((permission) => (
            <MenuItem
              key={`user-permission-id-${permission.id}`}
              sx={{ height: 48, gap: 2 }}
              selected={permission?.store?.id === profile?.me?.connectedStore?.id}
              onClick={() => {
                if (permission?.store?.id === profile?.me?.connectedStore?.id) {
                  popover.onClose();
                  return;
                }
                onUpdateConnectedStore(permission.store.id);
              }}
            >
              <Avatar
                alt="Logo da Loja"
                src={permission.store.profileImage || imagePlaceholder}
                sx={{ width: 24, height: 24 }}
              />

              <ListItemText
                primary={permission?.store?.name}
                secondary={userTypeChoices[permission?.userType as PermissionUserType]}
                sx={{ flexGrow: 1 }}
              />

              {permission?.store?.isActive && (
                <Label color={permission?.store?.plan === 'FREE' ? 'default' : 'info'}>
                  {planChoices[permission?.store?.plan as keyof typeof planChoices]}
                </Label>
              )}

              {!permission?.store?.isActive && (
                <Label color="default" variant="outlined">
                  Inativo
                </Label>
              )}
            </MenuItem>
          ))}
        </MenuList>
      </CustomPopover>
    </>
  );
}
