import { PermissionUserType, useGetProfileQuery } from 'src/graphql/generated';

// ----------------------------------------------------------------------
export function useHasPermission() {
  const profileRequestResponse = useGetProfileQuery({ fetchPolicy: 'no-cache' });
  const connectedStoreId = profileRequestResponse.data?.me?.connectedStore?.id;

  const storePermissions = profileRequestResponse.data?.me?.permissions
    .filter(permission => connectedStoreId === permission.store.id)

  const adminPermissions = storePermissions?.
    map(permission => permission.userType === PermissionUserType.Admin).
    filter(isAdminPerm => isAdminPerm).length;

  const employeePermissions = storePermissions?.
    map(permission => permission.userType === PermissionUserType.Employee).
    filter(isAdminPerm => isAdminPerm).length;

  const sellerPermissions = storePermissions?.
    map(permission => permission.userType === PermissionUserType.Seller).
    filter(isSellerPerm => isSellerPerm).length;

  return {
    hasAdminPermission: adminPermissions && adminPermissions > 0,
    hasEmployeePermission: employeePermissions && employeePermissions > 0,
    hasSellerPermissions: sellerPermissions && sellerPermissions > 0,
    storePermissions,
    isLoading: profileRequestResponse?.loading,
    data: profileRequestResponse?.data,
  };
}
