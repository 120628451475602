import { memo, forwardRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import NumberFormat, { NumberFormatProps, FormatInputValueFunction } from 'react-number-format';

import TextField, { TextFieldProps } from '@mui/material/TextField';

import { Masks } from '../../utils/masks';

// ----------------------------------------------------------------------

type Props = TextFieldProps & {
  name: string;
  formatMask: keyof typeof Masks;
};

// ----------------------------------------------------------------------

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  format: string;
}

const NumericFormatCustom = forwardRef<NumberFormatProps, CustomProps>((props, ref) => {
  const { onChange, format, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format={format}
    />
  );
});

function RHFMaskTextField({
  name,
  onChange,
  formatMask,
  helperText,
  type,
  InputProps,
  ...other
}: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          variant={other.disabled ? 'filled' : 'outlined'}
          fullWidth
          type={type}
          value={type === 'number' && field.value === 0 ? '' : field.value}
          onChange={(event) => {
            if (type === 'number') {
              field.onChange(Number(event.target.value));
            } else {
              field.onChange(event.target.value);
            }
          }}
          error={!!error}
          helperText={error ? error?.message : helperText}
          InputProps={{
            inputComponent: NumericFormatCustom as any,
            inputProps: {
              format: Masks[formatMask] as FormatInputValueFunction,
            },
            ...InputProps,
          }}
          {...other}
        />
      )}
    />
  );
}

export default memo(RHFMaskTextField);
