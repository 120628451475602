import {
  GiftbackStatus,
  CustomerGender,
  PermissionUserType,
  GiftbackConfigValueType,
} from 'src/graphql/generated';

import { LabelColor } from 'src/components/label';

export const rmfChoices = {
  CHAMPIONS: 'Campeão',
  LOYAL_CUSTOMERS: 'Leal',
  NEW_CUSTOMERS: 'Recente',
  PROMISING: 'Promissor',
  NEED_ATTENTION: 'Precisa de atenção',
  ABOUT_TO_SLEEP: 'Prestes a Dormir',
  AT_RISK: 'Em risco',
  CANT_LOSE_THEM: 'Não pode perder',
  HIBERNATING: 'Hibernando',
  LOST: 'Perdido',
  NOT_INFORMED: 'Indefinido',
  POTENTIAL_LOYALIST: 'Cliente em potencial',
};

export const statusChoices: {
  [K in GiftbackStatus]: {
    label: string;
    color: LabelColor;
  };
} = {
  ACTIVE: {
    label: 'Ativo',
    color: 'success',
  },
  REDEEMED: {
    label: 'Resgatado',
    color: 'secondary',
  },
  EXPIRED: {
    label: 'Expirado',
    color: 'extra',
  },
  CANCELLED: {
    label: 'Cancelado',
    color: 'error',
  },
  OVERDUE: {
    label: 'Vencido',
    color: 'default',
  }
};

export const genderChoices: { [K in CustomerGender]: string } = {
  MALE: 'Masculino',
  FEMALE: 'Feminino',
  NOT_INFORMED: 'Indefinido',
};

export const userTypeChoices: { [k in PermissionUserType]: string } = {
  ADMIN: 'Administrador',
  EMPLOYEE: 'Funcionário',
  SELLER: 'Vendedor'
};

export const userStateChoices: { [k in string]: string } = {
  activated: 'Ativo',
  blocked: 'Bloqueado',
};

export const valueTypeGiftBackConfig: { [k in GiftbackConfigValueType]: string } = {
  FIXED_VALUE: 'R$',
  PERCENTAGE: '%',
};

export const translateEnumToPortuguese = <T extends Record<string, string>>(
  key: string,
  obj: T
): string | undefined => {
  if (Object.prototype.hasOwnProperty.call(obj, key)) {
    return obj[key];
  }
  return undefined;
};

export const importedFromChoices = {
  OMIE: 'Omie',
  TRAY: 'Tray',
  PDV: 'PDV',
  SPREADSHEET: 'Planilha',
  TOTEM: 'Totem',
  MOEGO: 'MoeGo',
  SYSTEM: 'Sistema Zeki',
  WEBHOOK: 'Webhook',
  SGI: 'SGI',
};
