import { find } from 'lodash';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';

import { LoadingButton } from '@mui/lab';
import Grid from '@mui/material/Unstable_Grid2';
import { Box, Card, Stack, Button, ListItemText } from '@mui/material';

import { useLocales } from 'src/locales/use-locales';
import {
  GiftbackConfigValueType,
  useGetGiftbackConfigQuery,
  useUpdateGiftbackConfigMutation,
  UpdateGiftbackConfigMutationVariables as TVariables,
} from 'src/graphql/generated';

import Iconify from 'src/components/iconify';
import FormProvider from 'src/components/hook-form/form-provider';
import {
  RHFCheckbox,
  RHFTextField,
  RHFAutocomplete,
  RHFMaskTextField,
} from 'src/components/hook-form';

import {
  TYPES_VALUE,
  ConfigFormGiftBackSchema,
  ConfigFormGiftBackSchemaDefaultValues,
} from './config-form-schema';

// ----------------------------------------------------------------------

export default function ConfigFormGiftBack() {
  const [enableForm, setEnableForm] = useState<boolean>(false);
  const { currentLang } = useLocales();

  const methods = useForm({
    resolver: yupResolver(ConfigFormGiftBackSchema),
    defaultValues: ConfigFormGiftBackSchemaDefaultValues,
  });

  const configGiftBack = useGetGiftbackConfigQuery();
  const [updateConfigGiftBack] = useUpdateGiftbackConfigMutation();
  const { enqueueSnackbar } = useSnackbar();

  const { handleSubmit, reset, formState, watch } = methods;
  const watchedMinimumValueType = watch('minimumValueType');
  const watchedDiscountLimitEnabled = watch('discountLimitEnabled');
  const isFixedValue = watchedMinimumValueType?.value === GiftbackConfigValueType.FixedValue;
  const isPercentage = watchedMinimumValueType?.value === GiftbackConfigValueType.Percentage;

  const onSubmit = handleSubmit(async (data) => {
    const variables: TVariables = {
      input: {
        valueType: data.valueType?.value as GiftbackConfigValueType,
        value: data.value,
        discountLimitEnabled: data.discountLimitEnabled,
        discountLimit: data.discountLimit ? Number(data.discountLimit) / 100 : null,
        minPurchaseValueForCreate: Number(data.minPurchaseValueForCreate) / 100,
        dueDate: Number(data.dueDate),
        minimumValueType: data?.minimumValueType?.value as GiftbackConfigValueType,
        maximumDiscountPercentage: isPercentage
          ? Number(data.maximumDiscountPercentage) / 100
          : null,
        minPurchaseValueForRedeem: isFixedValue
          ? Number(data.minPurchaseValueForRedeem) / 100
          : null,
      },
    };
    try {
      await updateConfigGiftBack({ variables });

      enqueueSnackbar('Dados atualizados!', { variant: 'success' });

      await configGiftBack.refetch();

      setEnableForm(false);
    } catch (error) {
      console.error(error);

      if (error?.message) {
        enqueueSnackbar(error?.message, { variant: 'error' });

        return;
      }
      enqueueSnackbar('Não foi possível atualizar o usuário!', { variant: 'error' });
    }
  });

  useEffect(() => {
    const updateFields = () => {
      if (configGiftBack.data?.getGiftbackConfig) {
        const {
          valueType,
          value,
          dueDate,
          minPurchaseValueForCreate,
          minPurchaseValueForRedeem,
          minimumValueType,
          maximumDiscountPercentage,
          discountLimitEnabled,
          discountLimit,
        } = configGiftBack.data.getGiftbackConfig;

        const foundValueType = find(TYPES_VALUE, ['value', valueType]);
        const foundMinimumValueType = find(TYPES_VALUE, ['value', minimumValueType]);

        reset({
          valueType: foundValueType,
          discountLimitEnabled: !!discountLimitEnabled,
          discountLimit: String(discountLimit * 100),
          value: String(value),
          dueDate: String(dueDate),
          minPurchaseValueForCreate: minPurchaseValueForCreate
            ? String(minPurchaseValueForCreate * 100)
            : '',
          minPurchaseValueForRedeem: minPurchaseValueForRedeem
            ? String(minPurchaseValueForRedeem * 100)
            : '',
          minimumValueType: foundMinimumValueType,
          maximumDiscountPercentage: maximumDiscountPercentage
            ? String(maximumDiscountPercentage * 100)
            : '',
        });
      }
    };

    updateFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configGiftBack.data?.getGiftbackConfig, enableForm]);

  return (
    <FormProvider methods={methods} onSubmit={onSubmit!}>
      <Grid container spacing={3}>
        <Grid xs={12} gap={3} display="flex" flexDirection="column">
          <ListItemText
            primary="Configure o Giftback de acordo com a sua empresa!"
            secondary={
              <>
                Personalize seus giftbacks escolhendo entre descontos fixos em{' '}
                {currentLang.currencyNamePlural} ({currentLang.currencyShort}) ou percentuais (%)
                nas vendas.
                <br /> <br />
                Por exemplo, ofereça um giftback com desconto de {currentLang.currencyShort}10,00 ou
                10% do valor venda.
                <br /> <br />
                Defina a validade do Giftback e ajuste as configurações de vendas para criação e
                resgate do Giftback.
              </>
            }
            primaryTypographyProps={{ typography: 'h5', mb: 0.5, component: 'h2' }}
            secondaryTypographyProps={{ component: 'span' }}
            sx={{
              pr: 3,
              pl: 3,
            }}
          />
          <Card sx={{ p: 3 }}>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
              sx={{ marginBottom: '1rem' }}
            >
              <Stack display="flex" flexDirection="row" gap="0.5rem">
                <RHFAutocomplete
                  disabled={!enableForm}
                  name="valueType"
                  label="Tipo"
                  options={TYPES_VALUE}
                />
                <RHFTextField
                  disabled={!enableForm}
                  name="value"
                  label="Valor"
                  helperText={
                    <Stack component="span" direction="row" alignItems="center">
                      <Iconify icon="eva:info-fill" width={16} sx={{ mr: 0.5 }} />
                      Desconto do Giftback em{' '}
                      {currentLang.currencyShort === 'R$' ? 'Real' : 'Dollar'} (
                      {currentLang.currencyShort}) ou Percentual (%).
                    </Stack>
                  }
                />
              </Stack>
              <RHFMaskTextField
                disabled={!enableForm}
                name="minPurchaseValueForCreate"
                label="Valor mínimo para gerar"
                helperText={
                  <Stack component="span" direction="row" alignItems="center">
                    <Iconify icon="eva:info-fill" width={16} sx={{ mr: 0.5 }} />
                    Valor mínimo da venda para gerar o giftback.
                  </Stack>
                }
                formatMask="MONEY"
              />
              <RHFTextField
                disabled={!enableForm}
                name="dueDate"
                label="Tempo ativo"
                helperText={
                  <Stack component="span" direction="row" alignItems="center">
                    <Iconify icon="eva:info-fill" width={16} sx={{ mr: 0.5 }} />
                    Quantos dias corridos o giftback vai ficar ativo.
                  </Stack>
                }
              />
              <Stack display="flex" flexDirection="row" gap="0.5rem">
                <RHFAutocomplete
                  disabled={!enableForm}
                  name="minimumValueType"
                  label="Tipo"
                  options={TYPES_VALUE}
                />
                {isFixedValue && (
                  <RHFMaskTextField
                    disabled={!enableForm}
                    name="minPurchaseValueForRedeem"
                    label="Valor mínimo para resgatar"
                    helperText={
                      <Stack component="span" direction="row" alignItems="center">
                        <Iconify icon="eva:info-fill" width={16} sx={{ mr: 0.5 }} />
                        Valor mínimo da venda para resgatar o giftback.
                      </Stack>
                    }
                    formatMask="MONEY"
                  />
                )}
                {isPercentage && (
                  <RHFMaskTextField
                    disabled={!enableForm}
                    name="maximumDiscountPercentage"
                    label="Percentual máximo de desconto"
                    helperText={
                      <Stack component="span" direction="row" alignItems="center">
                        <Iconify icon="eva:info-fill" width={16} sx={{ mr: 0.5 }} />
                        Valor máximo que o cupom pode representar na próxima compra
                      </Stack>
                    }
                    formatMask="PORCENTAGEM"
                  />
                )}
              </Stack>
              <Stack display="flex" flexDirection="row" gap="0.5rem">
                <RHFCheckbox
                  disabled={!enableForm}
                  name="discountLimitEnabled"
                  label="Habilitar Desconto máximo"
                />
                <RHFMaskTextField
                  disabled={!watchedDiscountLimitEnabled || !enableForm}
                  name="discountLimit"
                  label="Valor máximo do giftback"
                  helperText={
                    <Stack component="span" direction="row" alignItems="center">
                      <Iconify icon="eva:info-fill" width={16} sx={{ mr: 0.5 }} />
                      Valor máximo que um giftback pode ter
                    </Stack>
                  }
                  formatMask="MONEY"
                />
              </Stack>
            </Box>
            <Box>
              {enableForm && (
                <Stack
                  flexDirection="row"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                  sx={{ mt: 3, gap: 1 }}
                >
                  <Button variant="outlined" color="error" onClick={() => setEnableForm(false)}>
                    Cancelar
                  </Button>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    loading={formState.isSubmitting}
                  >
                    Salvar
                  </LoadingButton>
                </Stack>
              )}
              {!enableForm && (
                <Stack display="flex" flexDirection="row" justifyContent="flex-end">
                  <Button variant="contained" color="primary" onClick={() => setEnableForm(true)}>
                    Editar
                  </Button>
                </Stack>
              )}
            </Box>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
